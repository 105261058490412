
/**
 * 2024-01-09 call with Shari and Marnie Delaney
 * next steps...
    + (1) install the dyslexia font - Open Dyslexic https://opendyslexic.org/; downloaded from the GitHub repository
    + (2) "Basic" and "Advanced" options menus - need to determine what options are basic and advanced
    + (3) button to select/deselect "Save my preferences for later visits"
    + (4) populate the dictionary - setup Butter; code to get dictionary from Butter
    - (5) more work on the "page structure" option - display in separate modal; heading tags as links, which needs navigation code to be able to scroll heading tag into view
    + (6) size/resize the menu - "top-left 50%x50%", "top-right 50%x50%", "bottom-right 50%x50%", "bottom-left 50%x50%", center autoxauto
 * 
 */

import { getA11yDictionary,
         getDictionary } from '~/utils/butterUtils'

export default {
  props: ['isActive'],
  data () {
    return {
      textSelection: '',
      currentTextSelection: null,
      cursorStyles: '',
      listOfHeadingTags: [],
      listOfLandmarks: [],
      listOfLinks: [],
      isSavingPreferences: false,
      isUsingAdvancedOptions: false,
      a11yPreferences: {
        animation: { animationOption: "default", animationDurationFactor: "default", transitionDurationFactor: "default" },
        backgroundColor: "default",
        cursorStyle: "default",
        dictionaryLookup: "default",
        fontFamily: "default",
        fontSize: { fontSizeOption: "default", fontSize: "default" },
        highlightLinks: "default",
        images: "default",
        letterSpacing: { letterSpacingOption: "default", letterSpacing: "default" },
        lineHeight: { lineHeightOption: "default", lineHeight: "default" },
        menuPosition: "default",
        menuType: "default",
        pageStructure: "default",
        textAlign: "default",
        textToSpeech: "default",      /** todo */
      }
    }
  },
  computed: {
    lang () {
      return this.$i18n.locale.toLowerCase()
    },
    buttonListAnimation () {
      return [
         {option:'default', label:this.dict.AM27, isAdvanced:false},
         {option:'stop', label:this.dict.AM28, isAdvanced:false},
         {option:'0.5X', label:this.dict.AM29, isAdvanced:false},
         {option:'slowdown', label:this.dict.AM30, isAdvanced:true},
         {option:'speedup', label:this.dict.AM31, isAdvanced:true},
       ]
    },
    buttonListBackgroundColor () {
      return [
        {option:'default', label:this.dict.AM27, isAdvanced:false},
        {option:'dark', label:this.dict.AM32, isAdvanced:false},
        {option:'intermediate', label:this.dict.AM33, isAdvanced:false},
        {option:'light', label:this.dict.AM34, isAdvanced:false},
      ]
    },
    buttonListCursorStyle () {
      return [
        {option:'default', label:this.dict.AM27, isAdvanced:false},
        {option:'big', label:this.dict.AM35, isAdvanced:false},
        {option:'underline', label:this.dict.AM36, isAdvanced:false},
        {option:'linemask', label:this.dict.AM37, isAdvanced:false},
      ]
    },
    buttonListDictionaryLookup () {
      return [
        {option:'default', label:this.dict.AM38, isAdvanced:true},
        {option:'on', label:this.dict.AM39, isAdvanced:true},
      ]
    },
    buttonListFontFamily () {
      return [
        {option:'default', label:this.dict.AM27, isAdvanced:false},
        {option:'Arial', label:'Arial', isAdvanced:true},
        {option:'OpenDyslexic', label:'OpenDyslexic', isAdvanced:false},
        {option:'Verdana', label:'Verdana', isAdvanced:false},
        {option:'Tahoma', label:'Tahoma', isAdvanced:true},
      ]
    },
    buttonListFontSize () {
      return [
        {option:'default', label:this.dict.AM27, isAdvanced:false},
        {option:'1.5X', label:this.dict.AM40, isAdvanced:false},
        {option:'2X', label:this.dict.AM41, isAdvanced:false},
        {option:'smaller', label:this.dict.AM42, isAdvanced:true},
        {option:'bigger', label:this.dict.AM43, isAdvanced:true},
      ]
    },
    buttonListHighlightLinks () {
      return [
        {option:'default', label:this.dict.AM38, isAdvanced:false},
        {option:'on', label:this.dict.AM39, isAdvanced:false},
      ]
    },
    buttonListImages () {
      return [
        {option:'default', label:this.dict.AM27, isAdvanced:false},
        {option:'hide', label:this.dict.AM44, isAdvanced:false},
        {option:'show', label:this.dict.AM45, isAdvanced:false},
      ]
    },
    buttonListLetterSpacing () {
      return [
        {option:'default', label:this.dict.AM27, isAdvanced:false},
        {option:'1.5X', label:this.dict.AM40, isAdvanced:false},
        {option:'2X', label:this.dict.AM41, isAdvanced:false},
        {option:'smaller', label:this.dict.AM42, isAdvanced:true},
        {option:'bigger', label:this.dict.AM43, isAdvanced:true},
      ]
    },
    buttonListLineHeight () {
      return [
        {option:'default', label:this.dict.AM27, isAdvanced:false},
        {option:'1.5X', label:this.dict.AM40, isAdvanced:false},
        {option:'2X', label:this.dict.AM41, isAdvanced:false},
        {option:'smaller', label:this.dict.AM42, isAdvanced:true},
        {option:'bigger', label:this.dict.AM43, isAdvanced:true},
      ]
    },
    buttonListMenuPosition () {
      return [
        {option:'default', label:this.dict.AM27, isAdvanced:true},
        {option:'top', label:this.dict.AM46, isAdvanced:true},
        {option:'bottom', label:this.dict.AM47, isAdvanced:true},
        {option:'left', label:this.dict.AM48, isAdvanced:true},
        {option:'right', label:this.dict.AM49, isAdvanced:true},
      ]
    },
    buttonListPageStructure () {
      return [
        {option:'default', label:this.dict.AM44, isAdvanced:true},
        {option:'show', label:this.dict.AM45, isAdvanced:true},
      ]
    },
    buttonListTextAlign () {
      return [
        {option:'default', label:this.dict.AM27, isAdvanced:false},
        {option:'left', label:this.dict.AM50, isAdvanced:false},
        {option:'center', label:this.dict.Q04, isAdvanced:false},
        {option:'right', label:this.dict.Q05, isAdvanced:false},
      ]
    },
    buttonListTextToSpeech () {
      return [
        {option:'default', label:this.dict.AM38, isAdvanced:false},
        {option:'on', label:this.dict.AM39, isAdvanced:false},
      ]
    },
    a11ydictionary () {
      if (this.butterA11yDictionary) {
        return getA11yDictionary(this.butterA11yDictionary, this.lang, this.$store.state.showDictionaryKeys)
      } else {
        return { "ERROR": "error"}
      }
    },
    dict () {
      if (this.butterDictionary) {
        return getDictionary(this.butterDictionary, this.lang, 'd', this.$store.state.showDictionaryKeys)
      } else {
        return { "ERROR": "error"}
      }
    },
    butterA11yDictionary () {
      return this.$store.state.butterA11yDictionary
    },
    butterDictionary () {
      return this.$store.state.butterDictionary
    },
  },
  methods: {
    doCloseModal () {
      this.listOfHeadingTags = []
      this.listOfLandmarks = []
      this.listOfLinks = []

      if (this.isSavingPreferences) {
        localStorage.setItem('a11yStoredPrefs', JSON.stringify(this.a11yPreferences))
      } else {
        localStorage.removeItem('a11yStoredPrefs')
      }

      this.$emit('close')

      const dialog = this.$refs.a11ymenu
      dialog.close()
    },
    doOpenModal () {
      const dialog = this.$refs.a11ymenu
      dialog.showModal()
    },
    doDictionaryLookup (param) {
      /**
       * notes:
       * (1) Userway will ignore a textSelection containing more than one word
       * 
       * (2) VFA dictionary allows phrases
       *     JSON dictionary
       *        dictionary={
       *          word_1: definition_word_1,
       *          word_2: definition_word_2 },
       *          ...
       *        }
       *      example:
       *        {
       *         "voluminous": "having or marked by great volume or bulk : large; also : full",
       *         "numerous": "filling or capable of filling a large volume or several volumes"
       *        }
       */

      const lookupTerm = param.trim()
      const lookupTermLC = lookupTerm.toLowerCase()
      let definition = this.a11ydictionary[lookupTerm]
      definition = definition ? definition : ( this.a11ydictionary[lookupTermLC] ? this.a11ydictionary[lookupTermLC] : "Not found in dictionary" )

      this.$buefy.dialog.alert({
        title: lookupTerm,
        message: definition,
        confirmText: this.dict.AM05,
        type: 'is-info',
        hasIcon: false,
      })
    },
    doSetColors (background_colors_option, link_highlights_option) {
      const rootElement = document.documentElement

      const color_theme_option = background_colors_option !== 'intermediate' ? background_colors_option : 'default'
      const link_highlights_theme_option = link_highlights_option === "on" ? link_highlights_option : "off"

      const bodyBackgroundColor = getComputedStyle(rootElement,null).getPropertyValue('--body-background-color-'+color_theme_option)
      rootElement.style.setProperty('--body-background-color', bodyBackgroundColor)
      const bodyBorderColor = getComputedStyle(rootElement,null).getPropertyValue('--body-border-color-'+color_theme_option)
      rootElement.style.setProperty('--body-border-color', bodyBorderColor)
      const bodyColor = getComputedStyle(rootElement,null).getPropertyValue('--body-color-'+color_theme_option)
      rootElement.style.setProperty('--body-color', bodyColor)

      const buttonBackgroundColor = getComputedStyle(rootElement,null).getPropertyValue('--button-background-color-'+color_theme_option)
      rootElement.style.setProperty('--button-background-color', buttonBackgroundColor)
      const buttonBorderColor = getComputedStyle(rootElement,null).getPropertyValue('--button-border-color-'+color_theme_option)
      rootElement.style.setProperty('--button-border-color', buttonBorderColor)
      const buttonColor = getComputedStyle(rootElement,null).getPropertyValue('--button-color-'+color_theme_option)
      rootElement.style.setProperty('--button-color', buttonColor)
      const buttonOutline = getComputedStyle(rootElement,null).getPropertyValue('--button-outline-'+color_theme_option)
      rootElement.style.setProperty('--button-outline', buttonOutline)

      const interactiveBackgroundColor = getComputedStyle(rootElement,null).getPropertyValue('--interactive-background-color-'+color_theme_option)
      rootElement.style.setProperty('--interactive-background-color', interactiveBackgroundColor)
      const interactiveBorderColor = getComputedStyle(rootElement,null).getPropertyValue('--interactive-border-color-'+color_theme_option)
      rootElement.style.setProperty('--interactive-border-color', interactiveBorderColor)
      const interactiveColor = getComputedStyle(rootElement,null).getPropertyValue('--interactive-color-'+color_theme_option)
      rootElement.style.setProperty('--interactive-color', interactiveColor)

      const linkBackgroundColor = getComputedStyle(rootElement,null).getPropertyValue('--link-background-color-'+color_theme_option+'-highlight-'+link_highlights_theme_option)
      rootElement.style.setProperty('--link-background-color', linkBackgroundColor)
      const linkBorderColor = getComputedStyle(rootElement,null).getPropertyValue('--link-border-color-'+color_theme_option+'-highlight-'+link_highlights_theme_option)
      rootElement.style.setProperty('--link-border-color', linkBorderColor)
      const linkColor = getComputedStyle(rootElement,null).getPropertyValue('--link-color-'+color_theme_option+'-highlight-'+link_highlights_theme_option)
      rootElement.style.setProperty('--link-color', linkColor)
      const linkOutline =  getComputedStyle(rootElement,null).getPropertyValue('--link-outline-color-'+color_theme_option+'-highlight-'+link_highlights_theme_option)
      rootElement.style.setProperty('--link-outline', linkOutline)

      const sandboxWarningBackgroundColor = getComputedStyle(rootElement,null).getPropertyValue('--sandbox-warning-background-color-'+color_theme_option)
      rootElement.style.setProperty('--sandbox-warning-background-color', sandboxWarningBackgroundColor)

      const tableBackgroundColor = getComputedStyle(rootElement,null).getPropertyValue('--table-background-color-'+color_theme_option)
      rootElement.style.setProperty('--table-background-color', tableBackgroundColor)
      const tableColor = getComputedStyle(rootElement,null).getPropertyValue('--table-color-'+color_theme_option)
      rootElement.style.setProperty('--table-color', tableColor)

      const vfaFooterBackgroundColor = getComputedStyle(rootElement,null).getPropertyValue('--vfa-footer-background-color-'+color_theme_option)
      rootElement.style.setProperty('--vfa-footer-background-color', vfaFooterBackgroundColor)

      const vfaTagBackgroundColor = getComputedStyle(rootElement,null).getPropertyValue('--vfa-tag-background-color-'+color_theme_option)
      rootElement.style.setProperty('--vfa-tag-background-color', vfaTagBackgroundColor)
      const vfaTagColor = getComputedStyle(rootElement,null).getPropertyValue('--vfa-tag-color-'+color_theme_option)
      rootElement.style.setProperty('--vfa-tag-color', vfaTagColor)
      const vfaTagOutline = getComputedStyle(rootElement,null).getPropertyValue('--vfa-tag-outline-'+color_theme_option)
      rootElement.style.setProperty('--vfa-tag-outline', vfaTagOutline)

      // apply the color filter value after all the color properties have been set
      let filterValue = getComputedStyle(rootElement,null).getPropertyValue('--filter-value-default')
          filterValue = background_colors_option !== 'intermediate' ? filterValue : 1
      rootElement.style.setProperty('--filter-value', filterValue)
    },
    clearPageStructure () {
      this.listOfHeadingTags = []
      this.listOfLandmarks = []
      this.listOfLinks = []
    },
    showPageStructure () {
      /**
       * notes about heading tags https://accessibility.psu.edu/headingshtml/
       * with thanks to https://stackoverflow.com/questions/7065562/how-do-i-get-all-h1-h2-h3-etc-elements-in-javascript
       */
      // heading tags
      this.listOfHeadingTags = [] // initialize on each call
      for (let i=1; i<=6; i++) {
        let headers = document.getElementsByTagName('h'+i);
        for (let j=0; j<headers.length; j++) {
            headers[j].classList.add('h')
        }
      }
      let headingTags = document.getElementsByClassName('h');
      for (let i=0; i<headingTags.length; i++) {
        this.listOfHeadingTags.push({"tagName":headingTags[i].tagName, "tagContents":headingTags[i].innerText})
        headingTags[i].classList.remove('h') // 
      }

      /*
        notes about landmarks
        https://stackoverflow.com/questions/65896130/accessing-the-accessibility-tree-with-code
        https://developer.mozilla.org/en-US/docs/Glossary/Accessibility_tree
        https://developer.mozilla.org/en-US/blog/aria-accessibility-html-landmark-roles/
        https://www.w3.org/WAI/GL/wiki/Using_ARIA_landmarks_to_identify_regions_of_a_page
        https://www.w3schools.com/accessibility/accessibility_landmarks.php
        https://fae.disability.illinois.edu/rulesets/LANDMARK_2/
        https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/landmark_role
      */
      // landmarks
      const landmarkRoles = ["banner", "header", "navigation", "nav", "search", "main", "region", "section", "complementary", "aside", "form", "contentinfo", "footer"]
      this.listOfLandmarks = [] // initialize on each call
      for (let i=0; i<landmarkRoles.length; i++) {
        let landmarks = document.getElementsByTagName(landmarkRoles[i]);
        for (let j=0; j<landmarks.length; j++) {
          if (!landmarks[j].classList.contains("exclude-from-page-structure")) {
            landmarks[j].classList.add('z')
          }
        }
      }

      let landmarkTags = document.getElementsByClassName('z');
      for (let i=0; i<landmarkTags.length; i++) {
        this.listOfLandmarks.push({ "landmarkName":landmarkTags[i].tagName, "landmarkContents":landmarkTags[i].innerText})
        landmarkTags[i].classList.remove('z')
      }

      // links
      this.listOfLinks = [] // initialize on each call
      let documentLinks = document.links
      for (let i=0; i<documentLinks.length; i++) {
        this.listOfLinks.push({ "href":documentLinks[i].href, "tagContents":documentLinks[i].innerText, "target":documentLinks[i].target })
      }
    },
    onMouseMoveCursorUnderlineHandler (event) {
      /**
       * put the underline image under the active cursor which contains the actual hotspot, 
       * otherwise the user must move the underline image up (and may cover the element)
       * so that the active cursor touches the element
       */
      const left = event.pageX;
      const top  = event.pageY;

      const cursor_underline = document.getElementById('cursor_underline')
      const cursor_underline_width = cursor_underline.width
      cursor_underline.style.left = left-(cursor_underline_width/2)+'px' /** cursor in the horizontal middle of the image */
      cursor_underline.style.top = top+16+'px' /** add 16px (1rem) so cursor's actual hotspot is outside of the image */
    },
    onMouseMoveCursorLineMaskHandler (event) {
      const heightOfLineMask = 48 /** 3 rem; actually half the height because we use heightOfLineMask above and below the active cursor */
      const cursorY = event.pageY; /** measured from top of page */

      const cursor_line_mask_top = document.getElementById('cursor_line_mask_top')
      cursor_line_mask_top.style.bottom = window.innerHeight-cursorY+heightOfLineMask+'px' /** measured from bottom of page */

      const cursor_line_mask_bottom = document.getElementById('cursor_line_mask_bottom')
      cursor_line_mask_bottom.style.top = cursorY+heightOfLineMask+'px'
    },
    onSelectionChangeHandler () {
      /**
       * notes:
       * will return partial textSelection between anchor and focus
       * can press control key to start another textSelection, which is added to previous textSelection(s)
       */
      /**
       * 2024-01-04 John Yee
       * with thanks to ...
       * https://stackoverflow.com/questions/49059855/how-should-selection-selectfinish-selectend-event-be-implemented
       */
      const timeToWait = 500 // delay that will be considered user has finished selecting text
      this.currentTextSelection = document.getSelection().toString();
      if (this.currentTextSelection != this.textSelection) {
          this.textSelection = this.currentTextSelection;
          if (this.selectionDelay) {
              window.clearTimeout(this.selectionDelay);
          }
          this.selectionDelay = window.setTimeout(() => {
              this.textSelection = '';
              this.selectionDelay = null;
              this.doDictionaryLookup(this.currentTextSelection);
          }, timeToWait);
      }
    },
    setAnimations (animationOption, animationDurationFactor, transitionDurationFactor) {
      const rootElement = document.documentElement

      rootElement.style.setProperty('--animation-duration-factor', animationDurationFactor)
      rootElement.style.setProperty('--transition-duration-factor', transitionDurationFactor)

      this.a11yPreferences.animation.animationOption = animationOption
      this.a11yPreferences.animation.animationDurationFactor = animationDurationFactor
      this.a11yPreferences.animation.transitionDurationFactor = transitionDurationFactor
    },
    selectAnimations (option) {
      const rootElement = document.documentElement
      const factorFaster = 0.80
      const factorSlower = (1.0/factorFaster)
      let animationDurationFactor
      let transitionDurationFactor

      switch (option) {
        case 'stop':
          animationDurationFactor = '0'
          transitionDurationFactor = '0'
          break;
        case '0.5X':
          animationDurationFactor = '2.0'
          transitionDurationFactor = '2.0'
          break;
        case '2X':
          animationDurationFactor = '0.5'
          transitionDurationFactor = '0.5'
          break;
        case 'slowdown':
          animationDurationFactor = getComputedStyle(rootElement,null).getPropertyValue('--animation-duration-factor')
          animationDurationFactor = parseFloat(animationDurationFactor)
          animationDurationFactor = animationDurationFactor * factorSlower

          transitionDurationFactor = getComputedStyle(rootElement,null).getPropertyValue('--transition-duration-factor')
          transitionDurationFactor = parseFloat(transitionDurationFactor)
          transitionDurationFactor = transitionDurationFactor * factorSlower
          break;
        case 'speedup':
          animationDurationFactor = getComputedStyle(rootElement,null).getPropertyValue('--animation-duration-factor')
          animationDurationFactor = parseFloat(animationDurationFactor)
          animationDurationFactor = animationDurationFactor > 0 ? animationDurationFactor : 1
          animationDurationFactor = animationDurationFactor * factorFaster

          transitionDurationFactor = getComputedStyle(rootElement,null).getPropertyValue('--transition-duration-factor')
          transitionDurationFactor = parseFloat(transitionDurationFactor)
          transitionDurationFactor = transitionDurationFactor > 0 ? transitionDurationFactor : 1
          transitionDurationFactor = transitionDurationFactor * factorFaster
          break;
        default:
          animationDurationFactor = getComputedStyle(rootElement,null).getPropertyValue('--animation-duration-factor-default')
          transitionDurationFactor = getComputedStyle(rootElement,null).getPropertyValue('--transition-duration-factor-default')
      }

      this.setAnimations(option, animationDurationFactor, transitionDurationFactor)
    },
    setBackgroundColor (option) {
      const linkHighlightsOption = this.a11yPreferences.highlightLinks
      this.doSetColors (option, linkHighlightsOption)
      this.a11yPreferences.backgroundColor = option
    },
    setCursor (option) {
      const rootElement = document.documentElement
      let cursor_underline = document.getElementById('cursor_underline')
      let cursor_line_mask_top = document.getElementById('cursor_line_mask_top')
      let cursor_line_mask_bottom = document.getElementById('cursor_line_mask_bottom')

      // remove any previously saved 'default' options before adding the newly selected option
      this.cursorStyles = this.cursorStyles.replaceAll('default', '')

      switch (option) {
        case 'big':
          const cursorButtonBig = getComputedStyle(rootElement,null).getPropertyValue('--cursor-button-big')
          const cursorDefaultBig = getComputedStyle(rootElement,null).getPropertyValue('--cursor-default-big')
          const cursorInputBig = getComputedStyle(rootElement,null).getPropertyValue('--cursor-input-big')
          const cursorLinkBig = getComputedStyle(rootElement,null).getPropertyValue('--cursor-link-big')
          const cursorSelectBig = getComputedStyle(rootElement,null).getPropertyValue('--cursor-select-big')

          rootElement.style.setProperty('--cursor-button', cursorButtonBig)
          rootElement.style.setProperty('--cursor-default', cursorDefaultBig)
          rootElement.style.setProperty('--cursor-input', cursorInputBig)
          rootElement.style.setProperty('--cursor-link', cursorLinkBig)
          rootElement.style.setProperty('--cursor-select', cursorSelectBig)

          this.cursorStyles += ' big'
          break
        case 'underline':
          document.addEventListener('mousemove', this.onMouseMoveCursorUnderlineHandler)
          cursor_underline.style.visibility='visible'

          this.cursorStyles += ' underline'
          break
        case 'linemask': 
          document.addEventListener('mousemove', this.onMouseMoveCursorLineMaskHandler)
          cursor_line_mask_top.style.visibility='visible'
          cursor_line_mask_bottom.style.visibility='visible'

          this.cursorStyles += ' linemask'
          break
        default:
          const visibilityCursorUnderlineDefault = getComputedStyle(rootElement,null).getPropertyValue('--visibility-cursor_underline-default')
          cursor_underline.style.visibility=visibilityCursorUnderlineDefault
          document.removeEventListener('mousemove', this.onMouseMoveCursorUnderlineHandler)

          const visibilityCursorLineMaskDefault = getComputedStyle(rootElement,null).getPropertyValue('--visibility-cursor_line_mask-default')
          cursor_line_mask_top.style.visibility=visibilityCursorLineMaskDefault
          cursor_line_mask_bottom.style.visibility=visibilityCursorLineMaskDefault
          document.removeEventListener('mousemove', this.onMouseMoveCursorLineMaskHandler)

          const cursorButtonDefault = getComputedStyle(rootElement,null).getPropertyValue('--cursor-button-default')
          const cursorDefaultDefault = getComputedStyle(rootElement,null).getPropertyValue('--cursor-default-default')
          const cursorInputDefault = getComputedStyle(rootElement,null).getPropertyValue('--cursor-input-default')
          const cursorLinkDefault = getComputedStyle(rootElement,null).getPropertyValue('--cursor-link-default')
          const cursorSelectDefault = getComputedStyle(rootElement,null).getPropertyValue('--cursor-select-default')

          rootElement.style.setProperty('--cursor-button', cursorButtonDefault)
          rootElement.style.setProperty('--cursor-default', cursorDefaultDefault)
          rootElement.style.setProperty('--cursor-input', cursorInputDefault)
          rootElement.style.setProperty('--cursor-link', cursorLinkDefault)
          rootElement.style.setProperty('--cursor-select', cursorSelectDefault)

          this.cursorStyles = 'default'
      }

      this.a11yPreferences.cursorStyle = this.cursorStyles.trim()
    },
    setDictionary (option) {
      switch (option) {
        case "on":
          document.addEventListener('selectionchange', this.onSelectionChangeHandler)
          break;
        default:
          document.removeEventListener('selectionchange', this.onSelectionChangeHandler)
      }

      this.a11yPreferences.dictionaryLookup = option
    },
    setFontFamily (option) {
      /**
       * installing a font
       * https://nuxt.com/docs/getting-started/styling
       * https://stackoverflow.com/questions/50674972/nuxt-js-include-font-files-use-static-or-assets
       */
      const rootElement = document.documentElement
      let fontFamily
      switch (option) {
        case 'Arial': 
          fontFamily = 'Arial'
          break
        case 'OpenDyslexic': 
          fontFamily = 'OpenDyslexic'
          break
        case 'Verdana': 
          fontFamily = 'Verdana'
          break
        case 'Tahoma': 
          fontFamily = 'Tahoma'
          break
        default:
          fontFamily = getComputedStyle(rootElement,null).getPropertyValue('--font-family-default')
      }

      rootElement.style.setProperty('--font-family', fontFamily)
      this.a11yPreferences.fontFamily = (option!=='default') ? fontFamily : 'default'
    },
    setFontSize(fontSizeOption, fontSize) {
      /**
       * want to handle situation where voter has set font size in the browser
       * https://stackoverflow.com/questions/58858373/research-how-to-get-the-default-fontsize-configured-in-the-browser
       */

      const rootElement = document.documentElement
      rootElement.style.setProperty('--font-size', fontSize)
      this.a11yPreferences.fontSize.fontSizeOption = fontSizeOption
      this.a11yPreferences.fontSize.fontSize = fontSize
    },
    selectFontSize (option) {
      const rootElement = document.documentElement
      const factorBigger = 1.10
      const factorSmaller = (1.0/factorBigger)
      let fontSize

      let fontS = getComputedStyle(rootElement,null).getPropertyValue('--font-size')
      let fontS3 = parseFloat(fontS)
      if (Number.isNaN(fontS3)) {
        fontS = '100' /* units = percent */
      }
      switch (option) {
        case '1.5X':
          fontSize = '150%'
          break
        case '2X':
          fontSize = '200%'
          break
        case 'bigger':
          fontSize = (factorBigger*parseFloat(fontS)).toString(10)+'%'
          break
        case 'smaller':
          fontSize = (factorSmaller*parseFloat(fontS)).toString(10)+'%'
          break
        case 'default':
        default:
          fontSize = getComputedStyle(rootElement,null).getPropertyValue('--font-size-default')
      }

      this.setFontSize(option, fontSize)
    },
    setHighlightLinks (option) {
      const backgroundColorOption = this.a11yPreferences.backgroundColor
      this.doSetColors (backgroundColorOption, option)
      this.a11yPreferences.highlightLinks = option
    },
    setImageVisibility(option) {
      const rootElement = document.documentElement
      switch (option) {
        case "hide":
          rootElement.style.setProperty('--visibility-images', 'hidden')
          break;
        default:
          let visibility = getComputedStyle(rootElement,null).getPropertyValue('--visibility-images-default')
          rootElement.style.setProperty('--visibility-images', visibility)
      }

      this.a11yPreferences.images = option
    },
    setLetterSpacing (letterSpacingOption, letterSpacing) {
      const rootElement = document.documentElement
      rootElement.style.setProperty('--letter-spacing', letterSpacing)
      this.a11yPreferences.letterSpacing.letterSpacingOption = letterSpacingOption
      this.a11yPreferences.letterSpacing.letterSpacing = letterSpacing
    },
    selectLetterSpacing (option) {
      const rootElement = document.documentElement
      const letterSpaceApprox = 0.05 /** 2024-01-20 John Yee: just an "eyeball" approximation to the "normal" spacing */
      const incrementPlus = 0.05
      const incrementMinus = -0.05
      let letterSpacing

      let letterSpacingDefault = getComputedStyle(rootElement,null).getPropertyValue('--letter-spacing-default')
      let letterSpDef3 = parseFloat(letterSpacingDefault)
      if (Number.isNaN(letterSpDef3)) {
        letterSpacingDefault = '0.0'
      }

      let letterSp = getComputedStyle(rootElement,null).getPropertyValue('--letter-spacing')
      let letterSp3 = parseFloat(letterSp)
      if (Number.isNaN(letterSp3)) {
        letterSp = '0.0'
      }

      switch (option) {
        case '1.5X':
          letterSpacing = (parseFloat(letterSpacingDefault)+0.5*letterSpaceApprox).toString(10)+'ch'
          break
        case '2X':
          letterSpacing = (parseFloat(letterSpacingDefault)+1.0*letterSpaceApprox).toString(10)+'ch'
          break
        case 'bigger':
          letterSpacing = (parseFloat(letterSp)+incrementPlus).toString(10)+'rem'
          break
        case 'smaller':
          letterSpacing = (parseFloat(letterSp)+incrementMinus).toString(10)+'rem'
          break
        case 'default':
        default:
          letterSpacing = getComputedStyle(rootElement,null).getPropertyValue('--letter-spacing-default')
      }

      this.setLetterSpacing(option, letterSpacing)
    },
    setLineHeight (lineHeightOption, lineHeight) {
      const rootElement = document.documentElement
      rootElement.style.setProperty('--line-height', lineHeight)
      this.a11yPreferences.lineHeight.lineHeightOption = lineHeightOption
      this.a11yPreferences.lineHeight.lineHeight = lineHeight
    },
    selectLineHeight (option) {
      const rootElement = document.documentElement
      const factorBigger = 1.11
      const factorSmaller = (1.0/factorBigger)
      let lineHeight

      let lineHeightDefault = getComputedStyle(rootElement,null).getPropertyValue('--line-height-default')
      let lineHDef = parseFloat(lineHeightDefault)
      if (Number.isNaN(lineHDef)) {
        lineHDef = '1.5'
      }

      let lineH = getComputedStyle(rootElement,null).getPropertyValue('--line-height')
      let lineH3 = parseFloat(lineH)
      if (Number.isNaN(lineH3)) {
        lineH = '1.5'
      }

      switch (option) {
        case '1.5X':
          lineHeight = (1.5*parseFloat(lineHDef)).toString(10)
          break
        case '2X':
          lineHeight = (2.0*parseFloat(lineHDef)).toString(10)
          break
        case 'bigger':
          lineHeight = (factorBigger*parseFloat(lineH)).toString(10)
          break
        case 'smaller':
          lineHeight = (factorSmaller*parseFloat(lineH)).toString(10)
          break
        case 'default':
        default:
          lineHeight = lineHeightDefault
      }

      this.setLineHeight(option, lineHeight)
    },
    setMenuPosition (option) {
      const rootElement = document.documentElement
      let marginOptions = this.a11yPreferences.menuPosition.replaceAll('default', '')

      let menuMarginTop = marginOptions.includes('top') ? '0rem' : 'auto'
      let menuMarginBottom = marginOptions.includes('bottom') ? '0rem' : 'auto'
      let menuMarginLeft = marginOptions.includes('left') ? '0rem' : 'auto'
      let menuMarginRight = marginOptions.includes('right') ? '0rem' : 'auto'
      let menuMargin
      let menuHeight
      let menuWidth

      switch (option) {
        case 'top':
          marginOptions = marginOptions.replaceAll('bottom', '').replaceAll('top', '')+ ' top'
          break
        case 'bottom':
          menuMarginBottom = '0rem'
          marginOptions = marginOptions.replaceAll('bottom', '').replaceAll('top', '')+ ' bottom'
          break
        case 'left':
          menuMarginLeft = '0rem'
          marginOptions = marginOptions.replaceAll('right', '').replaceAll('left', '')+ ' left'
          break
        case 'right':
          menuMarginRight = '0rem'
          marginOptions = marginOptions.replaceAll('right', '').replaceAll('left', '')+ ' right'
          break
        case 'default':
        default:
          marginOptions = 'default'
      }

      if (marginOptions.includes('top')) {
        menuMarginBottom = 'auto'
        menuMarginTop = '0rem'
        menuHeight = '50%'
      } else if (marginOptions.includes('bottom')) {
        menuMarginBottom = '0rem'
        menuMarginTop = 'auto'
        menuHeight = '50%'
      } else {
        menuMarginBottom = 'auto'
        menuMarginTop = 'auto'
        menuHeight = getComputedStyle(rootElement,null).getPropertyValue('--a11y-dialog-height-default')
      }

      if (marginOptions.includes('left')) {
        menuMarginLeft = '0rem'
        menuMarginRight = 'auto'
        menuWidth = '50%'
      } else if (marginOptions.includes('right')) {
        menuMarginLeft = 'auto'
        menuMarginRight = '0rem'
        menuWidth = '50%'
      } else {
        menuMarginLeft = 'auto'
        menuMarginRight = 'auto'
        menuWidth = getComputedStyle(rootElement,null).getPropertyValue('--a11y-dialog-width-default')
      }

      menuMargin = menuMarginTop + ' ' + menuMarginRight + ' ' + menuMarginBottom + ' ' + menuMarginLeft
      rootElement.style.setProperty('--a11y-dialog-margin', menuMargin)
      rootElement.style.setProperty('--a11y-dialog-height', menuHeight)
      rootElement.style.setProperty('--a11y-dialog-width', menuWidth)

      this.a11yPreferences.menuPosition = marginOptions
    },
    setMenuType (option) {
      switch (option) {
        case 'basic':
          this.isUsingAdvancedOptions = false
          break
        case 'advanced':
          this.isUsingAdvancedOptions = true
          break
        default:
          this.isUsingAdvancedOptions = false
      }

      this.a11yPreferences.menuType = option
    },
    selectPageStructure (option) {
      switch (option) {
        case 'show':
          this.showPageStructure()
          break
        case 'hide':
        default:
          this.clearPageStructure()
      }

      this.a11yPreferences.pageStructure = option
    },
    setTextAlign (option) {
      const rootElement = document.documentElement
      let textAlign
      switch (option) {
        case 'left': 
          textAlign = 'left'
          break
        case 'center': 
          textAlign = 'center'
          break
        case 'right': 
          textAlign = 'right'
          break
        default:
          textAlign = getComputedStyle(rootElement,null).getPropertyValue('--test-align-default')
      }

      rootElement.style.setProperty('text-align', textAlign)
      this.a11yPreferences.textAlign = (option!=='default') ? textAlign : 'default'
    },
    restoreAllDefaults () {
      this.selectAnimations('default')
      this.setBackgroundColor('default')
      this.setCursor('default')
      this.setDictionary('default')
      this.setFontFamily('default')
      this.selectFontSize('default')
      this.setHighlightLinks('default')
      this.setImageVisibility('default')
      this.selectLetterSpacing('default')
      this.selectLineHeight('default')
      this.setMenuPosition('default')
      this.setMenuType('default')
      this.selectPageStructure('default')
      this.setTextAlign('default')
    },
    restorePreferences (a11y) {
      this.setAnimations(a11y.animation.animationOption, a11y.animation.animationDurationFactor, a11y.animation.transitionDurationFactor)
      this.setBackgroundColor(a11y.backgroundColor)

      const cursorStyles = a11y.cursorStyle.split(" ")
      cursorStyles.forEach(element => {
        this.setCursor(element)
      });

      this.setDictionary(a11y.dictionaryLookup)
      this.setFontFamily(a11y.fontFamily)
      this.setFontSize(a11y.fontSize.fontSizeOption, a11y.fontSize.fontSize)
      this.setHighlightLinks(a11y.highlightLinks)
      this.setImageVisibility(a11y.images)
      this.setLetterSpacing(a11y.letterSpacing.letterSpacingOption, a11y.letterSpacing.letterSpacing)
      this.setLineHeight(a11y.lineHeight.lineHeightOption, a11y.lineHeight.lineHeight)

      const marginOptions = a11y.menuPosition.split(" ")
      marginOptions.forEach(element => {
        this.setMenuPosition(element)
      })

      this.setMenuType(a11y.menuType)
      this.selectPageStructure(a11y.pageStructure)
      this.setTextAlign(a11y.textAlign)
    },
  },
  mounted () {
    this.listOfHeadingTags = []
    this.listOfLandmarks = []
    this.listOfLinks = []

    const a11yStoredPrefs = localStorage.getItem('a11yStoredPrefs')
    if (a11yStoredPrefs) {
      this.isSavingPreferences = true
      this.a11yPreferences = JSON.parse(a11yStoredPrefs)
      this.restorePreferences(this.a11yPreferences)
    }
  },
  watch: {
    isActive (val) {
      if (val) {
        this.doOpenModal()
      } else {
        this.doCloseModal()
      }
    }
  },
}
